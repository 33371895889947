/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const dispatchStore = reactive({
	// * Set values in SearchOrderToDispatch.vue
	// * Query variables for the same qualifications
	dispatchExchangeRate: '',
	dispatchExchangeUnit: '',
	dispatchAddressId: '',
	invoiceAddressId: '',
	dispatchType: '',
	// ! ---------------------------------------------------
	// * for AddDispatch.vue tables rows
	orders: [],
	selectedOrders: [],
	// ! ------------------------------------------------------------------------
	// * AddDispatch.vue
	selectedDispatchHeaderState: {},
	selectedDispatchDate: new Date(),
	selectedDispatchLanguage: '',
	// * SearchResultListOrdersToDispatch.vue setFormFieldsValues
	ourCompany: '',
	customer: {},
	dispatchAddress: {},
	invoiceAddress: {},
	// ! ------------------------------------------------------------------------
	dispatchDate: '',
	documentCompanyID: '',
	documentOurCompany: '',
	documentNumber: '',
	// ! ------------------------------------------------------------------------
	reportOurCompany: '',
	reportDispatchNumber: '',
	showGetDispatchReport: false,
	showDispatchReport: false, // *
	// ! ------------------------------------------------------------------------
	orderTableColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '110px',
		},
		{
			label: 'Order Date',
			field: 'order_date',
			width: '135px',
		},
		{
			label: 'Customer Title',
			field: 'customer_title',
		},
		{
			label: 'Customer Nickname',
			field: 'customer_nickname',
		},
		{
			label: 'Customer ID',
			field: 'customer_id',
			width: '135px',
		},
	],
	// --------------------------------------------------------------------------
	selectOrderColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '110px',
		},
		{
			label: 'Order Date',
			field: 'order_date',
			width: '135px',
		},
		{
			label: 'Order Type',
			field: 'order_type',
			width: '115px',
		},
		{
			label: 'Order Exchange',
			field: 'order_exchange_unit',
			width: '115px',
		},
		{
			label: 'Address Nickname',
			field: 'order_headers_dispatch_address_addresses_rel.address_nickname',
		},
		{
			label: 'Order Total',
			field: 'order_total',
			html: true,
			type: 'number',
			width: '135px',
		},
		{
			label: 'Preview',
			field: 'preview',
			width: '50px',
			sortable: false,
		},
	],
	// ! --------------------------------------------------------------------------
	selectedOrdersColumns: [
		{
			label: 'Remove',
			field: 'remove',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '110px',
		},
		{
			label: 'Order Date',
			field: 'order_date',
			width: '135px',
		},
		{
			label: 'Order Type',
			field: 'order_type',
			width: '115px',
		},
		{
			label: 'Order Exchange',
			field: 'order_exchange_unit',
			width: '115px',
		},
		{
			label: 'Address Nickname',
			field: 'order_headers_dispatch_address_addresses_rel.address_nickname',
		},
		{
			label: 'Order Total',
			field: 'order_total',
			html: true,
			type: 'number',
			width: '135px',
		},
		{
			label: 'Preview',
			field: 'preview',
			width: '50px',
			sortable: false,
		},
	],
})
export default function useDispatchStore() {
	return {
		...toRefs(dispatchStore),
	}
}
