<!-- @format -->

<template>
	<div>
		<div
			style="
				display: flex;
				justify-content: start;
				font-weight: 900;
				background-color: rgb(76, 92, 122);
				padding: 20px;
			"
		>
			<b-button
				class="is-info margin-right-20"
				style="width: 250px"
				@click="refresh(radio)"
				>Refresh the data</b-button
			>
			<p class="content margin-right-10" style="color: white; margin-top: 10px">
				<b>Order Source:</b>
			</p>
			<div style="color: white">
				<b-radio
					v-model="radio"
					name="name"
					type="is-warning"
					native-value="us"
					style="margin-top: 10px"
				>
					The orders taken by us
				</b-radio>
				<b-radio
					v-model="radio"
					name="name"
					type="is-warning"
					native-value="internet"
				>
					Online orders from customers
				</b-radio>
			</div>
		</div>
		<div>
			<vue-good-table
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				@on-row-dblclick="onRowDoubleClick"
				@on-cell-click="onCellClick"
				:search-options="{
					enabled: true,
				}"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success" :disabled="isSelectDisabled">
							Select
						</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import Router from '@/router'
// GraphQL
import GetOrdersToDispatchQuery from '@/_srcv2/pages/dispatch/graphql/GetOrdersToDispatchQuery.gql'
import GetOrdersToDispatchToSameDispatchAddressQuery from '@/_srcv2/pages/dispatch/graphql/GetOrdersToDispatchToSameDispatchAddressQuery.gql'
// From NPM Package
import { reactive, computed, onMounted, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
// Composable scripts
import useDispatchStore from '@/_srcv2/pages/dispatch/_shared/useDispatchStore.js'

export default {
	name: 'SearchResultListOrdersToDispatch',
	// components: { SearchPopup },
	setup() {
		const radio = ref('us')
		// ! -----------------------------------------------------------------
		// * Get orders to dispatch & fill the table
		onMounted(() => {
			setInitialStateSearchResultListOrdersToDispatch()
				.then(() =>
					refetch()
						.then((result) => {
							console.log('result', result.data.order_headers)
						})
						.then(() => console.log('end of 1')),
				)
				.then(() => console.log('end of 2'))
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, onResult } = useQuery(
			GetOrdersToDispatchQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				order_source: radio.value,
			}),
			options,
		)
		// ! -----------------------------------------------------------------
		const ordersToDispatchList = ref([])
		const setRowsArray = (queryResult) => {
			ordersToDispatchList.value = queryResult.data.order_headers.map(
				(item) => {
					return {
						order_number: item.order_number,
						order_date: item.order_date,
						customer_title: item.customer.customer_title,
						customer_nickname: item.customer.customer_nickname,
						customer_id: item.customer.customer_id,
						// ----------------------------------------------------
						company_id: item.company_id,
						dispatch_address_id: item.dispatch_address_id,
						invoice_address_id: item.invoice_address_id,
						order_exchange_rate: item.order_exchange_rate,
						order_exchange_unit: item.order_exchange_unit,
						order_type: item.order_type,
						order_language: item.order_language,
					}
				},
			)
		}
		onResult((result) => {
			setRowsArray(result)
		})
		// ! ----------------------------------------------------------------------------
		// * Refresh the Data on the table
		const columns = computed(() => orderTableColumns.value)
		const rows = computed(() => ordersToDispatchList.value)
		const setRadio = (message) => {
			return new Promise((resolve, reject) => {
				if (message !== null || undefined) {
					radio.value = message
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const refetchData = () => {
			setInitialStateSearchResultListOrdersToDispatch().then(() => {
				refetch()
					.then((result) => {
						console.log('result', result.data.order_headers)
					})
					.then(() => console.log('end of 1'))
					.catch((err) => console.log('Error: ', err))
			})
		}
		const refresh = (message) => {
			setRadio(message).then(() => refetchData())
		}
		// ! ----------------------------------------------------------------------------
		// * Get dispatchStore
		const {
			ourCompany,
			customer,
			dispatchAddress, // * object
			orders, // * array
			selectedOrders, // * array
			dispatchExchangeUnit,
			dispatchExchangeRate,
			dispatchAddressId,
			invoiceAddressId,
			dispatchType,
			selectedDispatchLanguage,
			orderTableColumns, // * object
			// dispatchStore,
		} = useDispatchStore()
		// ! --------------------------------------------------------------------
		// * Set initial values on store (dispatchStore)
		const setInitialStateSearchResultListOrdersToDispatch = () => {
			const condition = true
			return new Promise((resolve, reject) => {
				if (condition !== null || undefined) {
					orders.value = []
					selectedOrders.value = []
					dispatchExchangeUnit.value = ''
					dispatchExchangeRate.value = ''
					invoiceAddressId.value = ''
					dispatchType.value = ''
					selectedDispatchLanguage.value = ''
					// ! -----------------------------------------------------------------
					customer.value = {}
					radio.value = 'us'
					resolve('ok')
				} else {
					const reason = new Error('Initial State could not be set')
					reject(reason)
				}
			})
		}
		// todo ---------------------------------------------------------------------
		// * Define the variables for the Query  GetOrdersToDispatchToSameDispatchAddressQuery
		const variablesQ2 = reactive({
			company_id: '',
			customer_id: '',
			dispatch_address_id: '',
			invoice_address_id: '',
			order_exchange_rate: 1,
			order_exchange_unit: 'kr.',
			order_type: 'Inland',
		})
		const optionsQ2 = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchQ2, onResult: onResultQ2 } = useQuery(
			GetOrdersToDispatchToSameDispatchAddressQuery,
			() => variablesQ2,
			optionsQ2,
		)
		// ! ------------------------------------------------------------------------
		// * Set Query variables GetOrdersToDispatchToSameDispatchAddressQuery
		const isConfirmationShown = ref(false)
		onMounted(() => {
			console.log('******** onMounted is fired')
			isConfirmationShown.value = false
		})
		// ! ------------------------------------------------------------------------
		const setVariablesQ2 = (item) => {
			if (item !== null || undefined) {
				variablesQ2.company_id = item.company_id
				variablesQ2.customer_id = item.customer_id
				variablesQ2.dispatch_address_id = item.dispatch_address_id
				variablesQ2.invoice_address_id = item.invoice_address_id
				variablesQ2.order_exchange_rate = item.order_exchange_rate
				variablesQ2.order_exchange_unit = item.order_exchange_unit
				variablesQ2.order_type = item.order_type
				dispatchExchangeRate.value = item.order_exchange_rate
				dispatchExchangeUnit.value = item.order_exchange_unit
				dispatchAddressId.value = item.dispatch_address_id
				invoiceAddressId.value = item.invoice_address_id
				dispatchType.value = item.order_type
				selectedDispatchLanguage.value = item.order_language
				isConfirmationShown.value = true
				showConfirmation()
			}
		}
		// *------------------- Set Form Headers ---------------------------------
		const setFormFieldsValues = (item) => {
			console.log('orders-------', item)
			return new Promise((resolve, reject) => {
				if (item !== null || undefined) {
					ourCompany.value = item.companies[0].company_nickname
					customer.value = item.customers[0]
					dispatchAddress.value = item.addresses[0]
					orders.value = item.order_headers.map((item) => {
						return {
							order_date: item.order_date,
							order_type: item.order_type,
							order_exchange_rate: item.order_exchange_rate,
							order_exchange_unit: item.order_exchange_unit,
							order_headers_dispatch_address_addresses_rel:
								item.order_headers_dispatch_address_addresses_rel,
							order_heders_goods_transactions_rel:
								item.order_heders_goods_transactions_rel,
							order_lock: item.order_lock,
							order_number: item.order_number,
							order_total:
								item.order_heders_goods_transactions_rel_aggregate.aggregate.sum
									.line_price_total_credit +
								item.order_heders_goods_transactions_rel_aggregate.aggregate.sum
									.vat_credit,
						}
					})
					// ----------------------------------------------
					selectedOrders.value = []
					resolve('ok')
				} else {
					const reason = new Error('Data could not be set')
					reject(reason)
				}
			})
		}
		//  ! --------------------------------------------------------------------
		const isSelectDisabled = ref(false)
		// * Select The order on the table
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				isSelectDisabled.value = true
				console.log('params.row', params.row)
				setVariablesQ2(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			isSelectDisabled.value = true
			setVariablesQ2(params.row)
			// showPopup()
		}
		onResultQ2((result) => {
			console.log('*** onResult is fired', result.data)
			if (isConfirmationShown.value === true) {
				console.log('onResultQ2 isConfirmationShown', isConfirmationShown.value)
				setFormFieldsValues(result.data).then(() => {
					Store.dispatch('setIsButtonsDisabled', true)
					setTimeout(() => {
						isSelectDisabled.value = false
					}, 1500)
					Router.push({ name: 'AddDispatch' })
				})
			} else {
				console.log('else is fired')
			}
		})
		// *** -----------------------------------------------------------
		const showConfirmation = () => {
			if (
				confirm(
					'Are you sure to add new dispatch \n To cancel addition press "ok" \n To return back press "cancel"',
				)
			) {
				refetchQ2()
			} else {
				isConfirmationShown.value = false
			}
		}
		return {
			onRowDoubleClick,
			onCellClick,
			columns,
			rows,
			refresh,
			radio,
			confirm,
			isSelectDisabled,
		}
	},
}
</script>

<style scoped></style>
