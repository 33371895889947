var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"start","font-weight":"900","background-color":"rgb(76, 92, 122)","padding":"20px"}},[_c('b-button',{staticClass:"is-info margin-right-20",staticStyle:{"width":"250px"},on:{"click":function($event){return _vm.refresh(_vm.radio)}}},[_vm._v("Refresh the data")]),_vm._m(0),_c('div',{staticStyle:{"color":"white"}},[_c('b-radio',{staticStyle:{"margin-top":"10px"},attrs:{"name":"name","type":"is-warning","native-value":"us"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" The orders taken by us ")]),_c('b-radio',{attrs:{"name":"name","type":"is-warning","native-value":"internet"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" Online orders from customers ")])],1)],1),_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"search-options":{
				enabled: true,
			},"pagination-options":{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [5, 10, 15, 20],
				dropdownAllowAll: false,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success",attrs:{"disabled":_vm.isSelectDisabled}},[_vm._v(" Select ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"content margin-right-10",staticStyle:{"color":"white","margin-top":"10px"}},[_c('b',[_vm._v("Order Source:")])])}]

export { render, staticRenderFns }