<!-- @format -->

<template>
	<div>
		<layout-default-new>
			<sub-navbar page="Dispatch" />
			<div>
				<section class="hero is-danger margin-bottom-20">
					<div class="hero-body">
						<p class="title">Create dispatch</p>
						<p class="subtitle">Please select the order to dispatch</p>
					</div>
				</section>
				<div>
					<search-result-list-orders-to-dispatch />
				</div>
			</div>
		</layout-default-new>
	</div>
</template>

<script>
// Components
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import SearchResultListOrdersToDispatch from '@/_srcv2/pages/dispatch/add-dispatch/SearchResultListOrdersToDispatch.vue'

export default {
	name: 'ViewOrdersToDispatch',
	components: {
		SubNavbar,
		SearchResultListOrdersToDispatch,
	},
	setup() {
		return {}
	},
}
</script>

<style scoped></style>
